/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { MdDeleteForever } from 'react-icons/md';
import { GoPencil } from 'react-icons/go';
import { toast } from 'react-toastify';

import { Checkbox } from '@atlaskit/checkbox';
import InputSelect from '~/components/Inputs/InputSelect';
import InputText from '~/components/Inputs/InputText';
import { InputSelect as InputSelectNovo } from '~/components/NovosInputs';
import ToggleDefault from '~/components/ToggleDefault';

import { ButtonRow } from '~/pages/RegraDeLoja/styles';

import { useRegraLoja } from '~/pages/RegraDeLoja/RegraLojaContext';

import {
  ISelect,
  IBalanca,
  IFormBalanca,
  IInterface,
} from '~/pages/RegraDeLoja/types';
import api from '~/services/api';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import Separator from '~/components/Separator';
import { Col } from 'react-bootstrap';

const Balanca = () => {
  const [interfaces, setInterfaces] = useState<ISelect[]>([]);
  const [interfacesAtivas, setInterfacesAtivas] = useState<ISelect[]>([]);
  const [localInitInicializado, setLocalInitInicializado] = useState(false);

  const {
    formRegraLoja,
    changeFormRegraLoja,
    formBalanca,
    changeFormBalanca,
    handleClearFormBalanca,
    balancas,
    setBalancas,
    balancasInsert,
    setBalancasInsert,
    balancasUpdate,
    setBalancasUpdate,
    balancasDelete,
    setBalancasDelete,
    formTela: { register, setValue, control },
    tipoTotalizadoresBalanca,
    qntdDigts,
  } = useRegraLoja();

  useEffect(() => {
    try {
      api.get('/interface/1').then((res) => {
        const { data, success, message } = res.data;
        if (!success) {
          throw new Error(message);
        }

        const regs = data.map((inter: IInterface) => {
          return {
            value: inter.cod_interface,
            label: inter.des_interface,
          };
        });

        const regsAtivos = data
          .filter((i: any) => i.flg_inativo !== true)
          .map((inter: IInterface) => ({
            value: inter.cod_interface,
            label: inter.des_interface,
          }));

        setInterfaces(regs);
        setInterfacesAtivas(regsAtivos);
      });
    } catch (e: any) {
      toast.warn(e.message);
    }
  }, []);

  useEffect(() => {
    const regs = formRegraLoja.Balancas.balancas.map((item: IFormBalanca) => {
      return {
        uuid: item.uuid?.value,
        id: item.cod_interface.value.value,
        cod_parametro_balanca: item.cod_parametro_balanca.value.value,
        cod_interface: item.cod_interface.value.value,
        des_interface: interfaces.find(
          (i) => i.value === item.cod_interface.value.value,
        )?.label,
        des_path: item.des_path.value,
        flg_secao_unica: item.flg_secao_unica.value,
        flg_sem_digito_plu: item.flg_sem_digito_plu.value,
        flg_compacta_arq: item.flg_compacta_arq.value,
        flg_inativo: item.flg_inativo.value,
      };
    });
    setBalancas(regs);
  }, [formRegraLoja.Balancas.balancas, interfaces, setBalancas]);

  const balancaColumns = [
    {
      field: 'des_interface',
      headerName: 'Interface',
      width: 200,
      sortable: false,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
    {
      field: 'des_path',
      headerName: 'Salvar em',
      width: 400,
      sortable: false,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
    {
      field: 'flg_secao_unica',
      headerName: 'Seção Única?',
      width: 150,
      sortable: false,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Checkbox isChecked={row.flg_secao_unica} />
          </div>
        );
      },
    },
    {
      field: 'flg_sem_digito_plu',
      headerName: 'Gerar sem Dig.V?',
      width: 150,
      sortable: false,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Checkbox isChecked={row.flg_sem_digito_plu} />
          </div>
        );
      },
    },
    {
      field: 'flg_compacta_arq',
      headerName: 'Compactar Arquivos',
      width: 170,
      sortable: false,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Checkbox isChecked={row.flg_compacta_arq} />
          </div>
        );
      },
    },
    {
      field: 'flg_inativo',
      headerName: 'Inativo?',
      width: 95,
      sortable: false,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '95%',
            }}
          >
            <Checkbox isChecked={row.flg_inativo} />
          </div>
        );
      },
    },
    {
      field: '',
      headerName: 'Ações',
      sortable: false,
      width: 180,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onEdit = () => {
          const reg = formRegraLoja.Balancas.balancas.find(
            (item: IFormBalanca) => {
              return row.id === item.cod_interface.value.value;
            },
          );
          if (reg) {
            changeFormBalanca(reg);
          }
        };
        const onDelete = () => {
          const filtered = balancas.filter((val: IBalanca) => {
            return val.cod_interface !== row.cod_interface;
          });
          deleteBalanca(row);
          handleClearBalanca();
          setBalancas(filtered);
        };

        return (
          <>
            <ButtonRow type="button" onClick={onEdit}>
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={onDelete}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];

  function handleClearBalanca() {
    handleClearFormBalanca();
  }

  function validaFormBalanca(inputs: IFormBalanca): boolean {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formBalanca[Object.keys(inputs)[i] as keyof IFormBalanca].isRequired &&
        formBalanca[Object.keys(inputs)[i] as keyof IFormBalanca].isInvalid
      ) {
        return true;
      }
    }
    return false;
  }

  // eslint-disable-next-line no-shadow
  function handleAddBalancaOrUpdate(item: IFormBalanca) {
    const itemFormatado = {
      uuid: item.uuid.value,
      id: item.cod_interface.value.value,
      cod_parametro_balanca: item.cod_parametro_balanca.value.value,
      cod_interface: item.cod_interface.value.value,
      des_interface: interfaces.find(
        (i) => i.value === item.cod_interface.value.value,
      )?.label,
      des_path: item.des_path.value,
      flg_secao_unica: item.flg_secao_unica.value,
      flg_sem_digito_plu: item.flg_sem_digito_plu.value,
      flg_compacta_arq: item.flg_compacta_arq.value,
      flg_inativo: item.flg_inativo.value,
    };

    if (item.cod_parametro_balanca.value.value !== undefined) {
      // UPDATE
      const itemsUpdate = balancasUpdate;
      const index = itemsUpdate.findIndex(
        (i) =>
          i.cod_parametro_balanca === item.cod_parametro_balanca.value.value,
      );
      if (index === -1) {
        setBalancasUpdate([...itemsUpdate, itemFormatado]);
      } else {
        itemsUpdate[index] = itemFormatado;
        setBalancasUpdate(itemsUpdate);
      }
    } else {
      // INSERT
      const itemsInsert = balancasInsert;
      const index = itemsInsert.findIndex((i) => i.uuid === item.uuid.value);
      if (index === -1) {
        setBalancasInsert([...itemsInsert, itemFormatado]);
      } else {
        itemsInsert[index] = itemFormatado;
        setBalancasInsert(itemsInsert);
      }
    }
  }

  function deleteBalanca(row: any) {
    const filteredinsert = balancasInsert.filter((val: IBalanca) => {
      return val.uuid !== row.uuid;
    });
    const filteredUpdate = balancasUpdate.filter((val: IBalanca) => {
      return val.uuid !== row.uuid;
    });

    if (row.cod_parametro_balanca !== undefined) {
      setBalancasDelete([...balancasDelete, row]);
    }
    setBalancasInsert(filteredinsert);
    setBalancasUpdate(filteredUpdate);
  }
  function handleAddBalanca() {
    if (validaFormBalanca(formBalanca)) {
      setLocalInitInicializado(true);
      toast.warning('Há informações pendentes');
      return;
    }
    setLocalInitInicializado(false);
    // adicionar validação
    handleAddBalancaOrUpdate(formBalanca);
    const hasExists = formRegraLoja.Balancas.balancas.find((item) => {
      return item.uuid.value === formBalanca.uuid.value;
    });

    if (hasExists) {
      const regs = formRegraLoja.Balancas.balancas.map((item) => {
        if (item.uuid.value === formBalanca.uuid.value) {
          item = formBalanca;
        }
        return item;
      });

      changeFormRegraLoja({
        ...formRegraLoja,
        Balancas: {
          ...formRegraLoja.Balancas,
          balancas: regs,
        },
      });
      handleClearBalanca();
    } else {
      changeFormRegraLoja({
        ...formRegraLoja,
        Balancas: {
          ...formRegraLoja.Balancas,
          balancas: [...formRegraLoja.Balancas.balancas, formBalanca],
        },
      });
      handleClearBalanca();
    }
  }

  return (
    <>
      <div className="row">
        <Col sm="12">
          <Separator labelText="Leitura de Etiquetas de Balança" />
        </Col>
        <div className="col-sm-12 col-md-4">
          <InputSelectNovo
            label="Tipo"
            placeholder="Selecione o Tipo"
            name="tipo_totalizador_etq_bal"
            register={register}
            isError={formRegraLoja.tipo_totalizador_etq_bal.isInvalid}
            control={control}
            options={tipoTotalizadoresBalanca}
            changeSelected={(selected: any) => {
              changeFormRegraLoja({
                ...formRegraLoja,
                tipo_totalizador_etq_bal: {
                  ...formRegraLoja.tipo_totalizador_etq_bal,
                  value: selected,
                  isInvalid: selected.value === undefined,
                },
              });
              setValue('tipo_totalizador_etq_bal', selected);
            }}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <InputSelectNovo
            label="Qntd. Dígitos"
            placeholder="Selecione a Quantidade de Dígitos"
            name="qtd_dig_cod_bal"
            register={register}
            isError={formRegraLoja.qtd_dig_cod_bal.isInvalid}
            control={control}
            options={qntdDigts}
            changeSelected={(selected: any) => {
              changeFormRegraLoja({
                ...formRegraLoja,
                qtd_dig_cod_bal: {
                  ...formRegraLoja.qtd_dig_cod_bal,
                  value: selected,
                  isInvalid: selected.value === undefined,
                },
              });
              setValue('qtd_dig_cod_bal', selected);
            }}
          />
        </div>
        <div className="col-sm-12 col-md-2 mt-4">
          <ToggleDefault
            labelText="Sem Dígito Verificador?"
            setChecked={formRegraLoja.flg_sem_dig_verif_bal.value}
            onSwitch={() =>
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_sem_dig_verif_bal: {
                  ...formRegraLoja.flg_sem_dig_verif_bal,
                  value: !formRegraLoja.flg_sem_dig_verif_bal.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="row">
        <Col sm="12">
          <Separator labelText="Interface de Balança" />
        </Col>
        <div className="col-sm-12 col-md-4">
          <InputSelect
            options={interfacesAtivas}
            label="Interface"
            value={
              interfaces.find((item: ISelect) => {
                return item.value === formBalanca.cod_interface.value.value;
              }) ?? {
                value: undefined,
                label: 'Selecione',
              }
            }
            isRequired={formBalanca.cod_interface.isRequired}
            setInvalid={formBalanca.cod_interface.isInvalid}
            iniInicializado={localInitInicializado}
            onChange={(value: any, isInvalid = true) => {
              changeFormBalanca({
                ...formBalanca,
                cod_interface: {
                  ...formBalanca.cod_interface,
                  value,
                  isInvalid: value.value === -1,
                },
              });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-8">
          <InputText
            value={formBalanca.des_path.value || ''}
            maxLength={100}
            label="Salvar em"
            isDisabled
            placeholder=""
            isRequired={formBalanca.des_path.isRequired}
            setInvalid={formBalanca.des_path.isInvalid}
            iniInicializado={localInitInicializado}
            onChange={(value: string, isInvalid = false) => {
              changeFormBalanca({
                ...formBalanca,
                des_path: {
                  ...formBalanca.des_path,
                  value,
                  isInvalid: value === '',
                },
              });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-2 mt-4">
          <ToggleDefault
            labelText="Seção Única?"
            setChecked={formBalanca.flg_secao_unica.value}
            onSwitch={() =>
              changeFormBalanca({
                ...formBalanca,
                flg_secao_unica: {
                  ...formBalanca.flg_secao_unica,
                  value: !formBalanca.flg_secao_unica.value,
                },
              })
            }
          />
        </div>

        <div className="col-sm-12 col-md-3 mt-4">
          <ToggleDefault
            labelText="Gerar Sem Dígito Verificador?"
            setChecked={formBalanca.flg_sem_digito_plu.value}
            onSwitch={() =>
              changeFormBalanca({
                ...formBalanca,
                flg_sem_digito_plu: {
                  ...formBalanca.flg_sem_digito_plu,
                  value: !formBalanca.flg_sem_digito_plu.value,
                },
              })
            }
          />
        </div>

        <div className="col-sm-12 col-md-3 mt-4">
          <ToggleDefault
            labelText="Compactar Arquivos?"
            setChecked={formBalanca.flg_compacta_arq.value}
            onSwitch={() =>
              changeFormBalanca({
                ...formBalanca,
                flg_compacta_arq: {
                  ...formBalanca.flg_compacta_arq,
                  value: !formBalanca.flg_compacta_arq.value,
                },
              })
            }
          />
        </div>

        <div className="col-sm-12 col-md-3 mt-4">
          <ToggleDefault
            labelText="Inativo?"
            setChecked={formBalanca.flg_inativo.value}
            onSwitch={() =>
              changeFormBalanca({
                ...formBalanca,
                flg_inativo: {
                  ...formBalanca.flg_inativo,
                  value: !formBalanca.flg_inativo.value,
                },
              })
            }
          />
        </div>

        <div className="col-sm-12 col-md-12">
          <div className="table-actions-buttons">
            <ConfirmButton onClick={handleAddBalanca}>Confirmar</ConfirmButton>
            <CancelButton onClick={handleClearBalanca}>Cancelar</CancelButton>
          </div>
        </div>

        <div
          className="col-sm-12 col-md-12"
          style={{ height: 380, width: '100%', marginTop: '16px' }}
        >
          <DataGrid
            rows={balancas}
            columns={balancaColumns}
            pageSize={5}
            localeText={{
              noRowsLabel: 'Nenhum registro encontrado',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar colunas',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            padding: '10px',
            justifyContent: 'flex-end',
          }}
        >
          <a
            href="https://konvix.zendesk.com/hc/pt-br/articles/28943754945563-Integrador#01J73QB58TC9SN9D54GFV65WPY"
            target="_blank"
            className="linkMaterial"
          >
            Como configurar uma balança?
          </a>
        </div>
      </div>
    </>
  );
};

export default Balanca;
